import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProjectContributors = () => (
  <Layout>
    <Seo title="About" />

    {/* <!-- This example requires Tailwind CSS v2.0+ --> */}

    <div className="max-w-2xl px-6 py-16 mx-auto space-y-12">
      <article className="space-y-8 bg-coolGray-100 text-coolGray-900">
        <div className="space-y-6">
          <h1 className="text-4xl font-bold md:tracking-tight md:text-5xl text-emerald-500">
            Project Contributors
          </h1>
          <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-coolGray-600">
            <div className="flex items-center md:space-x-2">
              <img
                src="https://i.ibb.co/nmJgC6w/ignite-youth-0650-1.png"
                alt=""
                className="w-4 h-4 border rounded-full bg-coolGray-500 border-coolGray-300"
              />
              <p className="text-sm">George Chakama • 17 March 2022</p>
            </div>
            <p className="flex-shrink-0 mt-3 text-sm md:mt-0">
              4 min read • 870 views
            </p>
          </div>
        </div>
        <div className="text-coolGray-800">
          <p className="mb-4">
            This project really kept me awake most of the days I was working on
            it, to avoid burn-out and drop the project I got help from some
            friends. I needed help building the phrases database and beta
            testers before Launching. I would like to thank everyone who
            sacrificed their time to help in making this app a success....
          </p>
          <h2 className="mb-4">My Project Contributors</h2>
          <ul className="list-decimal">
            <li>Leslie Bere</li>
            <li>Michelle Gopfa</li>
            <li>Rue Rusere</li>
            <li>Shamiso T. Sauka</li>
            <li>Delphina</li>
            <li>Keilah Madzvimbo</li>
            <li>Wadza_wacho</li>
            <li>Kuziva Madziva</li>
            <li>Phoebie</li>
          </ul>
        </div>
      </article>
      <div>
        <div className="flex flex-wrap py-6 space-x-2 border-t border-dashed border-coolGray-600">
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #StartUp
          </span>
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #SAAS
          </span>
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #HearMe
          </span>
        </div>
        <div className="space-y-2">
          <h4 className="text-lg font-semibold">Related posts</h4>
          <ul className="ml-4 space-y-1 list-disc">
            <li>
              <a
                rel="noopener noreferrer"
                href="/why-develop-hearme"
                className="hover:underline"
              >
                Why I Developed HearMe.
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="/projectcontributors"
                className="hover:underline"
              >
                Project Contributors
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <nav
      aria-label="breadcrumb"
      className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
    >
      <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
        <li className="flex items-center">
          <a
            rel="noopener noreferrer"
            href="/"
            title="Back to homepage"
            className="flex items-center hover:underline"
          >
            Home
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/know"
            className="flex items-center px-1 capitalize hover:underline font-bold "
          >
            About
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/started"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Features
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/contact"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Contact
          </a>
        </li>
      </ol>
      <footer className="animate-pulse">
        © {new Date().getFullYear()}, An Idea by
        {` `}
        <a className="text-center text-sm" href="https://www.chakama.co.zw">
          George Chakama
        </a>
      </footer>
    </nav>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p>
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p>
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default ProjectContributors
